import volunteerImg from '../../assets/volunteer1.jpg';
import { Link } from 'react-router-dom'; // Import Link from React Router
import Button from 'react-bootstrap/Button';
import SectionCard from "./ProgramCard";
import { sectionCards } from "./constants";

const ProgramsSection = () => {
  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0 });
};
    return (
        <>
        <section id="programs-section">
            {sectionCards.map((card) => <SectionCard key={card.title} data={card} />)}
        </section>
        
    <section id="volunteer-section" className="d-flex flex-column flex-md-row-reverse">
        <div className="volunteer-image">
          <img src={volunteerImg} alt="Group pic of students and volunteers" />
        </div>
        <div className="d-flex flex-column align-items-center align-items-md-start text">
        <h2 className="m-3">What is N.E.R.D.S, you say?</h2>
          <p className="m-3">Nurturing Education Readiness and Development from the Start!</p>
          <div className="d-flex flex-column align-items-center flex-md-row align-items-md-start">
          <Link to='../volunteer'>
                    <Button className="btn" onClick={goToTop}>See what works for you </Button>
                    </Link>
          </div>
        </div>
      </section>
    </>
    );
}

export default ProgramsSection;