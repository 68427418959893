import React from 'react';
import "./styles/styles.css";
import Down from "../homepage/down";
import ProgramsCard from "./programs-card";
import bookclub from "../../assets/Book-Club.jpeg";
import chemfun from "../../assets/ChemFun.jpeg";
import academictutoring from "../../assets/Academic-Tutoring.jpg";
import robotics from "../../assets/Robotics.jpg";
import leadnerdjr from "../../assets/Lead-NERD-Jr.jpg";
import arts from "../../assets/Arts.jpg";

const Activity = () => {
  return (
    <section id="hero-section">
      <header style={{ paddingLeft: 0 }} id="hero">
        <div className='p-5 text-center bg-image' style={{ backgroundImage: `url(${require('../../assets/images/image5.jpg')})` }}>
          <div className='text-container text-white'>
            <div className='d-flex flex-column justify-content-between align-items-center h-100'>
              <div className='hero-header'>
                <h1 className='mb-3'>Programs</h1>
              </div>
              <div className='hero-footer'>
                <h4 className='mb-5'>We offer variety of programs for all age levels ranging from elementary,middle school,High school,to college students.Choose a programs to learn more about!</h4>
              </div>
            </div>
          </div>
        </div>
      </header>

      <ProgramsCard
				right={true}
				title="Robotics"
				image={robotics}
				description="The Robotics Program aims to provide participating students with an engaging, supportive environment to 
        acquire skills that allow them to engage in STEM activities fully.  The Robotics NERDS initiative engages students, equips 
        them with critical thinking, problem-solving, creative, and collaborative skills, and helps them understand and apply 
        math and science content."
			/>

      <ProgramsCard
				right={false}
				title="CHEM FUN"
				image={chemfun}
				description="Chemistry Helping to Educate Minds For Understanding Now aims to nurture learning in children grades K-3, 
        offering room for play and exploration while learning chemistry concepts and basics. Children will naturally start to pick 
        up on the simple science concepts presented just by having fun with hands-on chemistry activities. Children are naturally 
        curious beings. They naturally want to know more,  go one step further, and fully explore something new. Nurturing their 
        curiosity will ignite their observation, critical thinking, and experimenting skills."
			/>
      
      <ProgramsCard
				right={true}
				title="Book Club"
				image={bookclub}
				description="Reading is important because it balances the application with theoretical concepts. The Book Club aims to 
        increase students’ motivation to read while increasing reading literacy skills through book readings and discussions. Students 
        gain exposure to various topics and concepts through reading material discussion while developing positive social interactions 
        with their peers."
			/>      
      
      <ProgramsCard
				right={false}
				title="Arts"
				image={arts}
				description="The Arts program offers opportunities for students to express themselves creatively through journalism, art, music, 
        etc. while developing skills to encourage the production of creative media messages, encouraging students to express their 
        feelings and thoughts through the arts."
			/>      
      
      <ProgramsCard
				right={true}
				title="NERD AMP (Academic Monitoring Program)"
				image={academictutoring}
				description="The program focuses on building reading confidence, revisiting foundational academic skills to build a strong base 
        for our students, and supporting students with homework.  Provides students with a positive atmosphere that encourages a sense 
        of self-­worth and achievement."
			/>      
      
      <ProgramsCard
				right={false}
				title="LEAD NERD Jr. Leadership Program"
				image={leadnerdjr}
				description="The Lead NERD Junior, Leadership Development initiative seeks to position NERDS as leaders in their schools
         and communities. Through the LEAD NERD Junior leadership development program, young people gain the skills to guide others, 
         influence opinions and policy, and become role models for their peers and younger generations. While focusing on civic 
         engagement, volunteerism, and character development."
			/>      

      <Down/>
    </section>
  );
};

export default Activity;
