import "./styles/history-card.css";

const HistoryCard = (props) => {
	return (
		<div className="nerds-history__slide">
			{!props.right && (
				<div className="nerds-history__slide-text">
					<p>{props.description}</p>
				</div>
			)}
			<div className="nerds-history__slide-imagedata">
				<div className="year-image-class">
					<img src={props.yearImage} alt="Year" className="year-image" />
				</div>
				<p>{props.title}</p>
				<img src={props.image} alt="Beginning" />
			</div>
			{props.right && (
				<div className="nerds-history__slide-text">
					<p>{props.description}</p>
				</div>
			)}
		</div>
	);
};

export default HistoryCard;
