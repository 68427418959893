import React from "react";
import NerdsLogo from '../../assets/NERDS_logo.png';

const Down = () => {
  return (
    <div className="down" style={{height: '300px'}}>
      <img className="navbar-logo" src={NerdsLogo} alt="NERDS logo"></img>
      <div className="content">
        <p className="down-content">
          Our mission is to inspire the inner nerd to learn for Life through
          engaging hands-on learning activities, defining a NERD as anyone who
          desires to learn. Our NERD Life program aims to develop students
          socially, educationally, and financially through four core principles:
          Social Development, Leadership Development, Self-Care, and Financial
          Literacy.
        </p>
        <div className="foot-links">
          <div className="foot-links-row" style={{fontWeight: 'bolder'}}>
            <a href="/about/mission">About us</a>
            <a href="/donate">Donate</a>
            <a href="/volunteer">Sign in</a>     
          </div>

          <div className="foot-links-row" style={{fontWeight: 'bolder'}}>
          <a href="/activities">Activities</a>
          <a href="/about/mission">Contact</a>
          <a href="/volunteer">Volunteer</a>
          </div>
        </div>
        </div>
        <div className="copyright">
          <div className="rights">
            © 2023 All Rights Reserved.
          </div>
		  <div className="terms">
			Terms of Service
		  </div>
        </div>
    </div>
  );
};

export default Down;
