import Button from 'react-bootstrap/Button';

const VolunteerHero = () => {
    return (
        <section id="hero-section">
            <header style={{ paddingLeft: 0 }} id="hero">
                <div className='p-5 text-center bg-image'>
                    <div className='text-container text-white'>
                        <div className='d-flex flex-column justify-content-between align-items-center h-100'>
                        <div className='hero-header'>
                                <h1 className='mb-3'>Get Involved</h1>
                            </div>
                            <div className='hero-footer'>
                                <h4 className='mb-5' style={{fontSize:'1.2rem'}}>Join in the NERDS mission and become a volunteer today. We offer opportunities with a range of time commitments to fit your unique needs. NERDLIFE volunteers have flexibility to sign up per event, NERD for Life volunteers commit to multiple sessions, and LEAD NERD Jr. is a semester long commitment.</h4>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSccAi85XxhSQEZg3YEEoKixemeLx_xH9ig-IIlnWrE8Es3pzg/viewform" target="_blank" rel="noopener noreferrer">
                                 <Button className="btn" >Volunteer Signup</Button>
                             </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </section>
    );
}

export default VolunteerHero;