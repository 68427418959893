import React, { useState } from "react";
import '../contact/styles/Contact.css';
import Down from "../homepage/down"

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [, setFormError] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        // Check if the input matches the pattern
        if (name === 'firstName') {
            const isValid = /^[A-Za-z -]+$/.test(value);
            setFirstNameError(!isValid);
        }
        else if (name === 'lastName') {
            const isValid = /^[A-Za-z -]+$/.test(value);
            setLastNameError(!isValid);
        }
        else if (name === 'email') {
            const isValid = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
            setEmailError(!isValid);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            console.log(formData);
            const response = await fetch('http://localhost:7071/api/HttpTrigger', {
                //mode:'no-cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                       
                },
                body: JSON.stringify(formData),
            });

            if(!response.ok) {
                throw new Error('Failed to submit form');
            }

            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            });

            setFormError(false);
        }

        catch (error) {
            console.error('Error submitting form:', error);
            setFormError(true);
        }


        };


    return (
        <>
        <div className="contact-form">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label htmlFor="first-name"> * First Name:</label>
                    <input
                        type="text"
                        id="first-name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        className={firstNameError ? 'error' : 'valid'} />
                    {firstNameError && <p className="error-message">First name can accept spaces, alphabets, and hyphens only.</p>}
                </div>

                <div className="input-container">
                    <label htmlFor="last-name"> * Last Name: </label>
                    <input
                        type="text"
                        id="last-name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                        className={lastNameError ? 'error' : 'valid'}
                    />
                    {lastNameError && <p className="error-message">Last name can accept spaces, alphabets, and hyphens only.</p>}
                </div>

                <div className="input-container">
                    <label htmlFor="email">* Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className={emailError ? 'error' : 'valid'} />
                    {emailError && <p className="error-message">Invalid email address. Please enter a different email address. Only letters, numbers, and non-consecutive dash (-), underscore (_), and/or period (.) are allowed.</p>}
                </div>

                <div className="input-container">
                    <label htmlFor="message">* Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        maxLength={5000} // Set maximum character limit
                    />
                    {/* Character counter */}
                    <span className="character-counter">{formData.message.length}/5000 characters used</span>
                </div>


                <button type="submit">Submit</button>
            </form>
        </div>
        <Down className = "down-component"/>
        </>
    );
};


export default Contact;
