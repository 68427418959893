import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import TopImg from "../../assets/home/image-top.png";
import LeftImg from "../../assets/home/image-left.jpg";
<div className="top-image">
    <img src={TopImg} alt="A student working on computer electronics" />
</div>

const DonateSection = () => {
    const goToTop = () => {
        window.scrollTo({ top: 0, left: 0 });
    };
    return (
        <>
            {/* Mobile view: Top image, bottom text | Desktop view: Left image, right image + text */}
            <section id="donate-section" className="d-flex align-items-center">
                {/* Mobile view: Hide image | Desktop view: Show image in the left */}
                <div className="left-image">
                    <img src={LeftImg} alt="A student working on computer electronics" />
                </div>
                <div className='right-content'>
                    <div className='right-image'>
                        <img src={TopImg} style={{marginTop : '30px', width: '90%', marginLeft: '20px'}}alt="A student working on computer electronics"></img>
                    </div>

                    <div className="text">
                        <h2 className="m-3">Programs and projects that provide hands-on experience</h2>
                        {/* Mobile view: Top text, bottom button (center-aligned) | Desktop view: Left text, right button (left-aligned) */}
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="m-3">You can donate to help us continue to inspire the inner NERD of the youth in our community.
                            </p>
                        </div>
                        <div className="d-flex justify-content-end"> {/* Align donate button to the right */}
                <Link to='../donate'>
                    <Button className="btn" onClick={goToTop}>Donate</Button>
                </Link>
            </div>
            </div>
                </div>
            </section>
        </>
    );
}

export default DonateSection;