import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from "react-bootstrap/Container";
import './styles/Caterpillar.css';
import paypalLogo from '../../assets/paypal-new.png';
import venmoLogo from '../../assets/venmo-new.png';
import cashAppLogo from '../../assets/cashapp-new.png';
import donateImage_1 from '../../assets/home/image-top.png';
import donateImage_2 from '../../assets/home/image-right.png';
import donateImage_3 from '../../assets/home/image-left.jpg';
import DonateHero from "./DonateHero";
import Down from "../homepage/down";

const Donate = () => {
    return (
        <>
            <DonateHero />

            <Container className="donate" style={{ marginBottom: '50px' }}>
                <Row>
                    <Col xs={12} className="mx-auto content-white-wrapper">
                        <Row className="paragraph-row">
                            <p className="text-center">Help us support students in underserved communities through donations. We accept PayPal, Venmo, and Cash App. All donations are tax-deductible.</p>
                            <p className="text-center" style={{ fontWeight: 'bold', fontSize: '2em', fontFamily: 'Inter', marginTop: '30px' }}>Choose your method</p>
                        </Row>
                        <div className="logo-container">
                            {/* PayPal Logo */}
                            <div className="logo_decs">
                                <a href="https://paypal.me/NERDSCincinnati?country.x=US&locale.x=en_US" target="_blank" rel="noreferrer" className="payment-method">
                                    <img src={paypalLogo} alt="PayPal Logo" className="paypal-logo" />
                                </a>
                            </div>
                            {/* Venmo Logo */}
                            <div className="logo_decs">
                                <a href="https://www.venmo.com/u/NERDS-NERDLIFE" target="_blank" rel="noreferrer" className="payment-method">
                                    <img src={venmoLogo} alt="Venmo Logo" className="venmo-logo" />
                                </a>
                            </div>
                            {/* Cash App Logo */}
                            <div className="logo_decs">
                                <a href="https://cash.app/$NERDS18" target="_blank" rel="noreferrer" className="payment-method">
                                    <img src={cashAppLogo} alt="Cash App Logo" className="cashapp-logo_new" />
                                </a>
                            </div>
                        </div>
                    </Col>
                    {/* </Container> */}
                    <div className="image-container">
                        <div className="image-with-text">
                            <img src={donateImage_1} alt="Donate" className="image" />
                            <p className="paragraph-content">
                                <span className="paragraph-highlight">514</span><br />
                                Students Served
                            </p>
                        </div>
                        <div className="image-with-text">
                            <img src={donateImage_2} alt="Donate" className="image" />
                            <p className="paragraph-content">
                                <span className="paragraph-highlight">48</span><br />
                                College credits earned by high school students
                            </p>
                        </div>
                        <div className="image-with-text">
                            <img src={donateImage_3} alt="Donate" className="image" />
                            <p className="paragraph-content">
                                <span className="paragraph-highlight">226</span><br />
                                Volunteers helping inspire the inner NERD
                            </p>
                        </div>
                    </div>
                </Row>

            </Container>
            
            <Down />

        </>
    );
}

export default Donate;