import "./styles/programs-card.css";

const ProgramsCard = (props) => {
	return (
		<div className="nerds-history__slide">
			{!props.right && (
				<div className="nerds-history__slide-text">
					<p>{props.description}</p>
				</div>
			)}
			<div className="nerds-history__slide-imagedata">

				<p>{props.title}</p>
				<img src={props.image} alt="Beginning" />
			</div>
			{props.right && (
				<div className="nerds-history__slide-text">
					<p>{props.description}</p>
				</div>
			)}
		</div>
	);
};

export default ProgramsCard;
