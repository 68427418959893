import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Mission from './components/about/mission';
import Activity from './components/activities/activitity';
import Contact from './components/contact/Contact';
import Donate from './components/get-involved/donate';
import Volunteer from './components/get-involved/volunteer';
import Home from './components/homepage/Home';
import Modal from 'react-modal';
import MainNavbar from './components/navbar/navbar';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById('root'));

function App() {
  return (
    <BrowserRouter>
      <MainNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<Outlet />}>
          <Route index element={<Mission />} />
        </Route>
        <Route path="contact" element={<Contact />} />
        <Route path="volunteer" element={<Outlet />}>
        <Route index element={<Volunteer />} />
        </Route>
        <Route path="Donate" element={<Outlet />}>
        <Route index element={<Donate />} />
        </Route>
        <Route path="activities" element={<Outlet />}>
          <Route index element={<Activity />} />
        </Route>
         <Route path="*" element={<h1>Non-existant</h1>} />
      </Routes>
      {/* Place footer here */}
    </BrowserRouter>
  );
}

export default App;
