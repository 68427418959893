import React from "react";
import VolunteerHero from "./VolunteerHero";
import Button from 'react-bootstrap/Button';
import LeftImg from '../../assets/nerdlifeimage.png';
import LeftImg1 from '../../assets/nerdforlifeimage.png';
import LeftImg2 from '../../assets/leadnerdimage.png';
import nerdlife from '../../assets/nerdlife.png';
import nerdForLife from '../../assets/Nerdforlife.png';
import leadNerd from '../../assets/leadnerd.png';
import volunteerImg from '../../assets/home/image-right.png';
import Down from "../homepage/down";


const Volunteer = () => {
	return (
		<>
			<VolunteerHero />
			<img src={nerdlife} alt="NERD LIFE TITLE" style={{ marginLeft: '130px' }} />
			<section id="volunteer-section1" className="d-flex flex-column text">
				<div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start text" style={{ paddingLeft: '120px' }}>
					<div className="left-image">
						<img src={LeftImg} alt="Group pic of students and volunteers" style={{ width: '70%', height: 'auto', borderRadius: '10px' }} />
					</div>
					<div className="text" style={{ paddingLeft: '20px', paddingBottom: '5px' }}>
						<p style={{ fontSize: '1.2rem', lineHeight: '1.3', maxWidth: '500px' }}>NERDLIFE volunteering is designed for short term
							events/occasional based needs. There is no commitment required. Volunteers are able to sign up
							for events on as needed basis. NERDLIFE may also volunteer with NERD for LIFE and LEAD NERD activities.</p>
						<ul style={{ fontSize: '1.2rem' }}>
							<li>Tutoring</li>
							<li>Fundraising</li>
							<li>Events</li>
							<li>STEM Playtime</li>
						</ul>
					</div>
				</div>
			</section>
			<div className="image" style={{ textAlign: 'right', marginRight: '190px' }}>
				<img src={nerdForLife} alt="NERD LIFE TITLE" />
			</div>

			<section id="volunteer-section1" className="d-flex flex-column text">
				<div className="d-flex flex-row align-items-center justify-content-center justify-content-md-end text" style={{ paddingRight: '20px' }}>
					<div className="text" style={{ paddingRight: '120px' }}>
						<p style={{ fontSize: '1.2rem', lineHeight: '1.3', maxWidth: '500px' }}>NERD for LIFE volunteer opportunities are designated for mid term commitment based on program needs. Volunteers commit to attend multiple sessions.</p>
						<ul style={{ fontSize: '1.2rem' }}>
							<li>Bike Club</li>
							<li>Garden Club</li>
							<li>Media Club</li>
							<li>Podcast</li>
						</ul>
					</div>
					<div className="left-image">
						<img src={LeftImg1} alt="Group pic of students and volunteers" style={{ width: '70%', height: 'auto', borderRadius: '10px' }} />
					</div>
				</div>
			</section>

			<img src={leadNerd} alt="NERD LIFE TITLE" style={{ marginLeft: '130px' }} />
			<section id="volunteer-section1" className="d-flex flex-column text">
				<div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start text" style={{ paddingLeft: '120px' }}>
					<div className="left-image">
						<img src={LeftImg2} alt="Group pic of students and volunteers" style={{ width: '70%', height: 'auto', borderRadius: '10px' }} />
					</div>
					<div className="text" style={{ paddingLeft: '20px' }}>
						<p style={{ fontSize: '1.2rem', lineHeight: '1.3', maxWidth: '400px' }}>LEAD NERD volunteers are long term (i.e. semester)commitments to managing and facilitating a specific program.</p>
						<ul style={{ fontSize: '1.2rem' }}>
							<li>Robotics</li>
							<li>3D Printing</li>
							<li>STEM Projects</li>
						</ul>
					</div>
				</div>
			</section>
			<section id="volunteer-section4" className="d-flex flex-column align-items-center flex-md-row-reverse">
				<div className="right-image">
					<img src={volunteerImg} alt="Group pic of students and volunteers" style={{ width: '70%', height: 'auto', paddingLeft: '20px', marginLeft:'50px' }} />
				</div>
				<div className="d-flex flex-column align-items-center align-items-md-start text" style={{ paddingLeft: '50px' }}>
					<h2 className="m-3">The best time to volunteer is today</h2>
					<p className="m-3" style={{ fontSize: '1.2rem', lineHeight: '1.3', maxWidth: '500px', textRendering: 'optimizeLegibility' }}>Sigup today to join the NERDS family! If you are looking for other no time commitment ways to support us, visit our donation page to learn more.</p>
					<div className="d-flex flex-column align-items-center flex-md-row align-items-md-start" style={{ paddingRight: '100px', paddingLeft: '15px' }}>
						<a href="https://docs.google.com/forms/d/e/1FAIpQLSccAi85XxhSQEZg3YEEoKixemeLx_xH9ig-IIlnWrE8Es3pzg/viewform" target="_blank" rel="noopener noreferrer">
							<Button className="btn">Volunteer Signup</Button>
						</a>
					</div>
				</div>
			</section>
			<Down />
		</>
	);
}

export default Volunteer;