import React from "react";
import "./styles/mission.css";
import coach_tee_icon from '../../assets/about/profiles/coach_tee.png';
import lily_maynard_icon from '../../assets/about/profiles/lily_maynard.png';
import ana_icon from '../../assets/about/profiles/ana.png';
import mike_lander_icon from '../../assets/about/profiles/mikeLander.png';
import Card from "./card";
import HistoryCard from "./history-card";
import image1 from "../../assets/2012_image.png";
import image2 from "../../assets/2016_image.png";
import image3 from "../../assets/2018_image.png";
import image4 from "../../assets/2019_image.png";
import image5 from "../../assets/2020_image.png";
import missionPage1 from "../../assets/mission-page/missionPage1.jpg";
import missionPage2 from "../../assets/mission-page/missionPage2.jpg";
import missionPage3 from "../../assets/mission-page/missionPage3.jpg";
import missionPage4 from "../../assets/mission-page/missionPage4.jpg";
import missionPage5 from "../../assets/mission-page/missionPage5.jpg";
import Down from "../homepage/down";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const AboutUs = () => {
	return (
		<>
		<div className="about-us" style={{paddingBottom:'100px'}}>
			<div className="about-us-top">
				<h1>About Us</h1>
				<iframe
					title="About us"
					width="700"
					height="364.9"
					src="https://drive.google.com/file/d/1GIXuG6RU3f8NopQijzcweUK-fdF_Dcpe/preview"
					allow="autoplay"
				></iframe>
				<div className="about-us-top__text">
					<p>
						We are a non-profit based in Cincinnati, Ohio who defines a NERD as
						anyone who desires to learn. Our mission is to inspire the inner
						NERD to learn for life through engaging hands-on learning
						activities. Our NERDLIFE program aims to develop students socially,
						educationally, and financially through core four principals: Social
						Development, Leadership Development, Self-Care, and Financial
						Literacy.
					</p>
					<Link to='../../activities'>
						<Button className="btn">Explore Programs</Button>
					</Link>
				</div>
			</div>
			<h1 className="about-us__meet-the-team">Meet The Team</h1>
			<div className="about-us-middle">
				<div className="about-us__team">
					<Card name="Tonkia Bridges" position="Coach Tee CEO/ Founder of NERDS President of BoD" linkedIn_url="https://www.linkedin.com/in/tonkia-bridges-b55065123" picture_url={coach_tee_icon}>
					</Card>
					<Card name="Anastasiya Chapko" position="Vice President Secretary of BoD" linkedIn_url="https://www.linkedin.com/in/anachapko" picture_url={ana_icon}>
					</Card>
					<Card name="Lily Maynard" position="Treasurer" linkedIn_url="https://www.linkedin.com/in/lilymaynard" picture_url={lily_maynard_icon}>
					</Card>
				</div>
				<div className="about-us__team">
					<Card name="Michael Lander" position="BoD member" linkedIn_url="https://www.linkedin.com/in/mike-lander-i-7630b0a8" picture_url={mike_lander_icon}>
					</Card>
				</div>
			</div>

			<h1>N.E.R.D.S. History</h1>
			<HistoryCard
				right={true}
				yearImage={image1}
				title="The Beginning"
				image={missionPage1}
				description="The dream started when Coach Tee was an academic advisor and
				basketball coach. She saw there was a need for change in early
				education. Although she couldn’t afford the early learning center
				she dreamed of, she focused on where she could make a difference"
			/>
			<HistoryCard
				yearImage={image2}
				title="Clubs"
				image={missionPage2}
				description="Coach Tee kick-started her after school clubs, beginning with book
				club. She Surveyed her middle school students and they expressed a
				lot of interest, but were fearful of working with the high school
				students. She created a separate program for the middle schoolers.
				The clubs began to flourish and participation skyrocketed."
			/>
			<HistoryCard
				right={true}
				yearImage={image3}
				title="Fundraising"
				image={missionPage3}
				description="As Coach Tee’s clubs started to grow in attendance, she realized she
				wouldn’t be able to supply the find alone anymore. She started
				fundraising and as it proved successful she realized she had
				something special - NERDS! She decided it was time to make things
				official and register NERDS as a non-profit."
			/>
			<HistoryCard
				yearImage={image4}
				title="NERDLIFE"
				image={missionPage4}
				description="Using her background in STEM education, she started the NERDLIFE
				program. This included programs such as book club, garden club,
				robotics club, and the birth of chem-fun kits. Creativity, literacy,
				and fun were infused in all of these clubs."
			/>
			<HistoryCard
				right={true}
				yearImage={image5}
				title="Moving Forward"
				image={missionPage5}
				description="NERDS continues to fulfill its mission - to inspire the inner nerd
				to learn for life. Everyone is a nerd in Coach Tee’s eyes. Being a
				nerd means having a passion for anything. We want to help students
				find and share their passions and gifts with the world. We are now
				looking into plans for an early learning center."
			/>
		</div>
		<Down />
	</>
	);
};

export default AboutUs;
