import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import Button from 'react-bootstrap/Button';
import RightImg from '../../assets/home/image-right.png';

function VolunteerSection() {
  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0 });
};
  return (
    <>
      {/* Mobile view: Top image, bottom text | Desktop view: Right image, left text */}
      <section id="volunteer-section" className="d-flex flex-column align-items-center flex-md-row-reverse">
        <div className="right-image">
          <img src={RightImg} alt="Group pic of students and volunteers" />
        </div>
        <div className="d-flex flex-column align-items-center align-items-md-start text">
          <h2 className="m-3">The best time to volunteer is today</h2>
          <p className="m-3">Get Involved with NERDS
            Are you ready to be a part of something truly transformative? Join NERDS in our mission to nurture the next generation of learners and innovators. There are multiple ways you can make a difference and get involved:
          </p>
          <div className="d-flex flex-column align-items-center flex-md-row align-items-md-start">
          <Link to='../contact'>
              <Button className="btn" onClick={goToTop}>Contact Us</Button>
            </Link>
            {/* Use the Link component to navigate to the donate page */}
          <Link to='../donate'>
              <Button className="btn" onClick={goToTop}>Donate</Button>
          </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default VolunteerSection;
